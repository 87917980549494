import React from 'react';
import '../App.css';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import User from '../models/UserModel';
import Study from '../models/StudyModel';
import { DateText } from './DateText';
import { StudyNameBadge } from './studies/StudyNameBadge';
import moment from 'moment';
import SurveysSparkline from './surveys/SurveysSparkline';


interface UsersListProps {
  data: Array<User>;
  studies?: Array<Study>;
  current_study?: Study;
  stats? : Array<any>;
}

interface UserStats {
  [userId: string]: {
      day: Date;
      surveys: number;
  };
}

export default class ListOfUsers extends React.Component<UsersListProps, {}> {

  study_link = this.props.current_study ? `?study_id=${this.props.current_study?.study_id}` : '';

  calculateMaxSurveys = (stats : Array<any>) => {
    // for each participant, find max
    //stats.forEach( )


    // then, find max of these

    return 2;
  } 

  findMaxSurveys(userData: Array<any>): number {
    let maxSurveys = 0;

    for (const userId in userData) {
        if (userData.hasOwnProperty(userId)) {
            const userSurveys = userData[userId];
            for (const surveyData of userSurveys) {
                const surveys = surveyData.surveys;
                if (surveys > maxSurveys) {
                    maxSurveys = surveys;
                }
            }
        }
    }
    return maxSurveys;
}

  maxSurveys : number = this.props.stats ? this.findMaxSurveys(this.props.stats) : 0;
  
  

  columns: ColumnsType<User> = [
    {
      title: 'Name',
      //width: '30%',
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      render: (u) => { return (<div className='participant-name'>
                                 <a href={`/user/${u.user_id}${this.study_link}`}>{u.full_name}</a>
                                 {this.props.current_study && this.props.stats ? <SurveysSparkline data={this.props.stats[u.user_id]}
                                                                                                   maxX={this.maxSurveys}/> : ''}
                               </div> ) },
    },
    {
      title: 'Studies',
      className : 'all-user-studies',      
      render: (u) => { return u.participant_in.map((s: any) => { return <StudyNameBadge name={s["name"]} url={`/user/${u.user_id}?study_id=${s["id"]}`} key={s["id"]}/> }) }
  
    },
    {
      title: 'Completed Surveys',
      dataIndex: 'num_surveys',
      filterSearch: true,
      sorter: (a, b) => a.num_surveys - b.num_surveys,
    },
    {
      title: 'Last Active',
      dataIndex: 'last_active',
      sorter: (a, b) => a.last_active!.getTime() - b.last_active!.getTime(),
      defaultSortOrder: 'descend',
      render: (d) => d > 0 ? <> <DateText date={new Date(d)}/> &nbsp;
                                <span className='date-from'>({moment(d).fromNow()})</span>
                             </>
                      : null,
    },
    {
      title: '',
      render: (u) => { return u.config_personalized ? <span className='personalized-tag'>personalized schedule</span> : '' } 
    }
  ];

  render() {
    return (
      <Table className='users-table' columns={this.columns} rowKey="user_id" dataSource={this.props.data} 
              pagination={{
                pageSize: 100,
                showTotal: total => `${total} total`,
                showSizeChanger : false
              }}/>
    );
  }
}






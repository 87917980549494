import * as API from '../api';

export default class Survey {
    template_id : string;
    study_id? : number;
    created_by : string;

    created_on : Date;

    data : any;

    template_data? : any;
 
    constructor(data : any) {
        this.template_id = data["template"];
        this.study_id = data["study_id"];
        this.created_by = data["created_by"];
        this.created_on = new Date(data["created_on"]);

        this.data = data["data"];
    }

    set_template = async () => {
        let response = await API.getSurveyTemplate(this.template_id);

        if (!response.ok) return false;

        let template = await response.json();
        this.template_data = template["data"]["tasks"];

        return true;
    }
}
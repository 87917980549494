import React from 'react';
import moment from 'moment';
import { BarChart, Tooltip, Bar, XAxis, YAxis } from 'recharts';

interface ChartProps {
  data : Array<any>;
  maxX : number; // to normalize scale for each participant
}

const SurveysSparkline: React.FC<ChartProps> = ({ data, maxX }: ChartProps) => {
  const getDates = (from : Date, to : Date) => {
      let daysArr = [new Date(from)];
      let tempDate = from;

      while (tempDate < to) {
          tempDate.setUTCDate(tempDate.getUTCDate() + 1);
          daysArr.push(new Date(tempDate));
      }

      return daysArr;
  }

  const isSameDay = (dateToCheck : Date, actualDate : Date) => {
    return dateToCheck.getDate() === actualDate.getDate() 
     && dateToCheck.getMonth() === actualDate.getMonth()
     && dateToCheck.getFullYear() === actualDate.getFullYear()
  }

  let imputed_data = [];
  let latest;

  if (data.length === 0) {
    latest = new Date(Date.now());

  } else {
    latest = new Date(data[data.length-1].day);
    data.forEach(e => {e.day = new Date(e.day)});
  }

  let earliest = new Date(latest)
  earliest = new Date(earliest.setDate(earliest.getDate() - 13));
  let dates = getDates(earliest, latest);

  for (let date of dates) {
    const existing = data.find(obj => {
      return isSameDay(obj.day, new Date(date))
    })

    imputed_data.push(existing ?? {day : date, surveys : 0})
  }

  return (<BarChart width={100} height={30} data={imputed_data} style={{borderBottom: '1px #8984d8ab solid'}}>
            <Tooltip labelFormatter={(label) => moment(label).format('ll')} position={{ x: 0, y: 30 }} wrapperStyle={{zIndex : 1000}}/>
            <XAxis dataKey="day" hide/>
            <YAxis domain={[0, maxX]} hide/>
            <Bar dataKey="surveys" fill="#8884d8" />
          </BarChart>);
};

export default SurveysSparkline;
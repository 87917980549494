import { useEffect, useState } from 'react';

import '../../App.css';
import * as API from '../../api';
import Study from '../../models/StudyModel';
import {HealthScatterPlot, SurveyBarChart} from './OverviewGraph';
import { StatsConfig } from '../../models/types';
import Loader from '../Loader';
import { Empty } from 'antd';


interface DataTableProps {
  user_id : string;
  study? : Study;
}

interface Stats {
  [key: string]: any[]
}

const UserOverview = (props : DataTableProps) => {
  const [stats, setStats] = useState<Stats>();
  const [config, setConfig] = useState<StatsConfig>();
  const [sort, setSort] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      let stats_response = await API.getHealthdataStats(props.user_id, props.study?.study_id);   
      
      if (stats_response.ok) {
        let stats_data = await stats_response.json();    

        stats_data.config.least_recent = new Date(stats_data.config.least_recent);
        stats_data.config.least_recent.setHours(0,0,0,0);
        stats_data.config.most_recent = new Date(stats_data.config.most_recent);
    
        // for some arcane reason, likely related to timezone localization, the end date needs 
        // to be adjusted so all of the data falls into the correct domain
        stats_data.config.most_recent.setDate(stats_data.config.most_recent.getDate() + 1);
        stats_data.config.most_recent.setHours(23,59,59,999);
    
        setStats(stats_data.stats);
        setConfig(stats_data.config);
      }

    } finally {
      setLoading(false);
    }
  };

  if (loading && !config) return <Loader/>;
  else if (!config) return <Empty style={{ padding: '75px 0'}}/>;
  
  return (
    <div className='overview-container'>
      {stats ? SurveyBarChart(stats['Surveys'], config) : ''}
      {stats ? Object.keys(stats).map((key : string) => { return HealthScatterPlot(key, stats[key], config)}) : ''}
    </div>
  );
  
}

export default UserOverview;

